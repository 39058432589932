<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">FrumTweets</a>
      </div>
    </nav>
    <div class="container mt-4">
      <h1>Welcome to Frum Tweets!</h1>
      <p>The internet's greatest collection of informative, hilarious and kosher tweets!</p>

      <div class="row">
        <div class="col-md-4" v-for="tweet in tweets" :key="tweet.id">
          <div class="card mb-4">
            <div class="card-body">
              <div v-html="tweet.embeddedHtml">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tweets: [],
      page: 1,
      perPage: 10,
    };
  },
  created() {
    this.loadTweets();
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    loadTweets() {
      // Load tweets from the JSON file (assuming it's in the public directory)
      // You can use axios or fetch to load the JSON file
      // Example:
      axios.get("/tweets.json").then((response) => {
        this.tweets = response.data;
      });

      // For simplicity, let's assume you have the JSON data directly here:
      const jsonData = [
        // Your JSON data goes here
      ];

      this.tweets = jsonData;
    },
    onScroll() {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const bottomOfPage = scrollY + windowHeight >= documentHeight;

      if (bottomOfPage) {
        this.page++;

      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles for your tweets here */
</style>
